import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { Divider, InputNumber, Input, Select, Checkbox } from 'antd';
import { Button, InputLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { OrderComposition, OrderCompositionProduct, Product } from '@types';
import { productHttp } from '@network';
import { moneyFormatter } from '@utils';

import cls from './orders.module.css';

interface Props {
  source?: string;
}

const OrderProducts = ({ source = 'composition' }: Props) => {
  const {
    field: { value, onChange },
  } = useController({ name: source });

  const [composition, setComposition] = useState<OrderComposition>(value || []);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    onChange(composition);
  }, [composition]);

  useEffect(() => {
    (async () => {
      const nextProducts = await productHttp.getListWithoutLimit();
      if (Array.isArray(nextProducts)) {
        setProducts(nextProducts.filter((product: Product) => product.isEnabled));
      }
    })();
    if ((value || []).length === 0) {
      addPosition();
    }
  }, []);

  const options = products.map(model => ({
    key: `${model.id}`,
    label: model.nameInfo,
    price: model.price,
  }));

  const addPosition = () => {
    const addedPosition: OrderCompositionProduct = {
      id: null,
      deliveryId: null,
      productId: null,
      qty: 1,
      productPrice: null,
      isExternal: false,
      externalProduct: '',
      externalShop: '',
    };
    setComposition([...composition, addedPosition]);
  };

  const removePosition = (index: number) => {
    const nextComposition = composition.filter((p, i) => i !== index);
    setComposition(nextComposition);
  };

  const onProductSelect = (index: number, productId: number) => {
    if (composition.find(position => position.productId === productId)) {
      return removePosition(index);
    }
    const nextComposition = composition.slice();
    nextComposition[index].id = index;
    nextComposition[index].productId = productId;
    nextComposition[index].productPrice =
      products.find((product) => product.id === productId)?.price || 0;
    setComposition(nextComposition);
  };

  const onQtyChange = (index: number, qty: any) => {
    const nextComposition = composition.slice();
    nextComposition[index].qty = qty;
    setComposition(nextComposition);
  };

  const onExternalSwap = (index: number, isChecked: boolean) => {
    const nextComposition = composition.slice();
    nextComposition[index].isExternal = isChecked;
    setComposition(nextComposition);
  };

  const onExternalName = (index: number, externalName: string) => {
    const nextComposition = composition.slice();
    nextComposition[index].externalProduct = externalName;
    setComposition(nextComposition);
  };

  const onExternalShop = (index: number, externalShop: string) => {
    const nextComposition = composition.slice();
    nextComposition[index].externalShop = externalShop;
    setComposition(nextComposition);
  };

  const onProductPriceChange = (index: number, productPrice: any) => {
    const nextComposition = composition.slice();
    nextComposition[index].productPrice = productPrice;
    setComposition(nextComposition);
  };

  return (
    <div className={cls.products}>
      <div className={cls.list}>
        {composition.map((position, index) => {
          return (
            <div className={cls.row} key={index}>
              <div className={`${cls.col} ${cls.number}`}>
                <div className={cls.numberValue}>{index + 1}</div>
              </div>
              <div className={cls.col}>
                <InputLabel shrink>Is External</InputLabel>
                <Checkbox
                  defaultChecked={position.isExternal}
                  onChange={e => onExternalSwap(index, e.target.checked)}
                />
              </div>
              {position.isExternal ? (
                <div className={cls.col}>
                  <InputLabel shrink>External Product</InputLabel>
                  <Input
                    className={cls.externalInput}
                    value={position.externalProduct}
                    onChange={e => onExternalName(index, e.target.value)}
                  />
                </div>
              ) : null}
              {position.isExternal ? (
                <div className={cls.col}>
                  <InputLabel shrink>External Shop</InputLabel>
                  <Input
                    className={cls.externalInput}
                    value={position.externalShop}
                    onChange={e => onExternalShop(index, e.target.value)}
                  />
                </div>
              ) : null}
              {!position.isExternal ? (
                <div className={`${cls.col} ${cls.product}`}>
                  <InputLabel shrink>Product</InputLabel>
                  <Select
                    onChange={productId => onProductSelect(index, +productId)}
                    value={position?.productId?.toString()}
                    className={cls.select}
                    showSearch
                    placeholder="Select a product"
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={options.map((product) => ({
                      value: product.key,
                      label: product.label,
                    }))}
                  />
                </div>
              ) : null}
              <div className={cls.col}>
                <InputLabel shrink>Quantity (1 or 1.225)</InputLabel>
                <InputNumber
                  value={position.qty}
                  onChange={qty => onQtyChange(index, qty)}
                  className={cls.input}
                  defaultValue={1}
                />
              </div>
              <div className={`${cls.col} ${cls.price}`}>
                <InputLabel shrink>Requested Price</InputLabel>
                <InputNumber
                  onChange={price => onProductPriceChange(index, price)}
                  className={cls.input}
                  value={position.productPrice || 0}
                  defaultValue={0}
                  min={0}
                  step={100}
                />
              </div>
              <div className={cls.col}>
                <InputLabel shrink>Remove</InputLabel>
                <Button className={cls.remove} type="submit" onClick={() => removePosition(index)}>
                  <DeleteIcon />
                </Button>
              </div>
            </div>
          );
        })}
      </div>
      <div className={cls.actions}>
        <Button onClick={addPosition} variant="outlined" size="small">
          <AddIcon /> Add position
        </Button>
      </div>
      <Divider />
      <div className={cls.summary}>
        <div className={cls.summaryTitle}>Total sum</div>
        <div className={cls.summaryValue}>
          {moneyFormatter.format(composition.reduce((total, product) =>
            total + ((product.productPrice ? product.productPrice : 0) * product.qty), 0),
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderProducts;
