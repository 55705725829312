import React, { useEffect, useLayoutEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';

import { RegisterCreditProgress, reportsHttp } from '@network';
import { InitialCard } from '@components';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';

interface Props {
  range: string;
  type: string;
}

export const RegisterCreditReport = ({ range, type }: Props) => {
  const [numbers, setNumbers] = useState<RegisterCreditProgress[]>([]);
  const cls = useStyles();
  const id = 'register-credit-report';

  const loadNumbers = async () => {
    try {
      const nextNumbers = await reportsHttp.registerCreditProgress({ range, type });
      setNumbers(nextNumbers);
    } catch (e) {}
  };

  useEffect(() => void loadNumbers(), [range, type]);

  useLayoutEffect(() => {
    const root = am5.Root.new(id);
    root.setThemes([
      am5themes_Animated.new(root),
    ]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        paddingLeft: 0,
        layout: root.verticalLayout,
      }),
    );

    chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root, {
        orientation: 'horizontal',
      }),
    );

    const xRenderer = am5xy.AxisRendererX.new(root, {
      minorGridEnabled: true,
      minGridDistance: 60,
    });
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'period',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      }),
    );
    xRenderer.grid.template.setAll({
      location: 1,
    });

    xAxis.data.setAll(numbers);

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        extraMax: 0.1,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
        }),
      }),
    );

    const series1 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Registrations / Apply rate',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'registeredAppliedRate',
        categoryXField: 'period',
        fill: am5.color('#229C63'),
        stroke: am5.color('#229C63'),
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          labelText: '{name}: {valueY}',
        }),
      }),
    );

    series1.strokes.template.setAll({
      strokeWidth: 3,
      templateField: 'strokeSettings',
    });

    series1.data.setAll(numbers);

    series1.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          strokeWidth: 3,
          stroke: series1.get('stroke'),
          radius: 5,
          fill: root.interfaceColors.get('background'),
        }),
      });
    });

    const series2 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Completed File / Apply rate',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'completedAppliedRate',
        categoryXField: 'period',
        fill: am5.color('#7B406D'),
        stroke: am5.color('#7B406D'),
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          labelText: '{name}: {valueY}',
        }),
      }),
    );

    series2.strokes.template.setAll({
      strokeWidth: 3,
      templateField: 'strokeSettings',
    });

    series2.data.setAll(numbers);

    series2.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          strokeWidth: 3,
          stroke: series2.get('stroke'),
          radius: 5,
          fill: root.interfaceColors.get('background'),
        }),
      });
    });

    chart.set('cursor', am5xy.XYCursor.new(root, {}));

    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      }),
    );
    legend.data.setAll(chart.series.values);

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [id, numbers]);
  const height = numbers && numbers.length > 0 ? 350 : 50;

  return (
    <InitialCard left="Registrations / Completed File / Apply - Rates"
      extraPadding autoHeight contentClass={cls.content} mt={22}>
      <div id={id} className={cls.chart} style={{ height, marginBottom: -45 }} />
    </InitialCard>
  );
};

const useStyles = makeStyles({
  content: {
    paddingTop: 30,
    paddingBottom: 17,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chart: {
    width: '100%',
    height: 350,
  },
});
