import { isProdServer } from '@utils/is-prod-server';

class DateFormatter {
  toDateTime(dateTime: string, withYear = false): string {
    return new Date(dateTime).toLocaleString([], {
      timeZone: isProdServer() ? 'America/Mexico_City' : 'Etc/UTC',
      day: '2-digit',
      month: 'short',
      year: withYear ? 'numeric' : undefined,
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  }

  toDate(dateTime: string) {
    return new Date(dateTime).toLocaleString([], {
      timeZone: isProdServer() ? 'America/Mexico_City' : 'Etc/UTC',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  }

  toTime(dateTime: Date | string, hour12 = false): string {
    return new Date(dateTime).toLocaleString([], {
      timeZone: isProdServer() ? 'America/Mexico_City' : 'Etc/UTC',
      hour: '2-digit',
      minute: '2-digit',
      hour12,
    });
  }

  toDateSlash(date?: Date | string, withTime = false): string | null {
    if (!date) {
      return null;
    }
    const { day, month, year } = this.getDayMonthYear(date);

    if (withTime) {
      const hourMinues = this.toTime(date);
      return `${day}/${month}/${year} ${hourMinues}`;
    }

    return `${day}/${month}/${year}`;
  }

  dateToDbFormat(date?: Date | string): string {
    const { day, month, year } = this.getDayMonthYear(date);

    return `${year}-${month}-${day}`;
  }

  getDayMonthYear(date?: Date | string): {day: string; month: string; year: string} {
    let d: Date = new Date();

    if (typeof date === 'string') {
      const parts = date.split('/');
      const dotParts = date.split('.');
      if (parts[2] && parts[2].length === 4) {
        d = new Date(+parts[2], +parts[1] - 1, +parts[0]);
      } else if (dotParts[2] && dotParts.length === 4) {
        d = new Date(+dotParts[2], +dotParts[1] - 1, +dotParts[0]);
      } else {
        d = new Date(date);
      }
    }
    isProdServer() && d.setUTCHours(d.getUTCHours() - 6);

    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return { day, month, year };
  }
}

export const dateFormatter = new DateFormatter();
