import React, { useRef, useState } from 'react';
import { clsx } from 'clsx';
import {
  Edit,
  EditProps,
  Link,
  SaveButton,
  SimpleForm,
  Toolbar,
  TopToolbar,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { Col, Row } from 'antd';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SaveFilled } from '@ant-design/icons';

import { CreditRisk, CreditStatus } from '@types';
import { creditRiskHttp } from '@network';
import { axiosErrorToString, isProdServer, rbac } from '@utils';
import { RiskChat } from '@pages/credit-risk/RiskChat';
import { RiskGeneral } from '@pages/credit-risk/RiskGeneral';
import { Terms } from '@pages/credit-risk/terms';
import { RiskTimeTracker } from '@pages/credit-risk/RiskTimeTracker';
import { RiskCreditReport } from '@pages/credit-risk/RiskCreditReport';
import { RiskProgressScore } from '@pages/credit-risk/RiskProgressScore';
import { BlueOutlineButton, Forbidden, InitialPage, Loader } from '@components';
import { useEditProps, useQuery, useRbacEdit, useRbacView, useRecord } from '@hooks';
import { CreditSurveyProgress } from '@pages/credit/CreditInitial/CreditSurveyProgress';
import { ExportRisk } from '@pages/credit-risk/export';
import { LegacyAnalysis } from '@pages/credit-risk/legacy-analysis';
import { Analysis } from '@pages/credit-risk/analysis';
import { RiskTapeFile } from '@pages/credit-risk/RiskTapeFile';
import { CreditSurveyView } from '@pages/credit/CreditEdit/CreditSurveyView';

const Title = () => {
  const record = useRecordContext();
  return (<span>CreditRisk # {record?.id}</span>);
};

export const CreditRiskEdit = (props: EditProps) => {
  const risk: CreditRisk | undefined = useRecord<CreditRisk>(props);
  const query = useQuery();
  const editProps = useEditProps();
  const notify = useNotify();
  const rbacView = useRbacView();
  const rbacEdit = useRbacEdit();
  const [isReportOpened, setReportOpened] = useState(false);
  const [isReportPrepared] = useState(true);
  const reportNodeRef = useRef(null);
  const cls = useStyles();

  const isSuperAdmin = rbac.isSuperadmin();
  const riskManagerReadonly = rbac.hasRiskManagerReadonlyPerm();
  const readonly = riskManagerReadonly && !isSuperAdmin;

  const backTo = risk ? `/credit/${risk.creditId}?report` : '#';
  const toRiskAnalysis = risk ? `/credit-risk/${risk.id}?analysis` : '#';
  const toRisk = risk ? `/credit-risk/${risk.id}` : '#';
  const showAnalysis = query.get('analysis') === '';

  if (!rbacView && !riskManagerReadonly) {
    return <Forbidden />;
  }

  const completeAnalysis = async () => {
    if (readonly) return;
    try {
      await creditRiskHttp.complete(risk.id);
      setReportOpened(true);
    } catch (e) {
      notify(axiosErrorToString(e));
    }
  };

  const showAnalysisBtn = risk?.credit.status === CreditStatus.BANK_REVISION
    || risk?.credit.user.responsibleAdminId === 42;

  const EditActions = () => {
    return rbacEdit ? (
      <Toolbar>
        <div className={cls.toolbar}>
          <div>
            <SaveButton label="Save" type="button" disabled={readonly} />
            <Link to={backTo}>
              <BlueOutlineButton>Go back</BlueOutlineButton>
            </Link>
          </div>
          {showAnalysisBtn ? (
            <Link to={toRiskAnalysis}>
              <Button variant="contained" color="secondary">Start Analysis</Button>
            </Link>
          ) : null}
        </div>
      </Toolbar>
    ) : null;
  };

  const AnalysisActions = () => {
    return (
      <>
        <TopToolbar className={cls.actions}>
          <SaveButton
            icon={<SaveFilled className={cls.actionIcon} />}
            label="Save"
            type="button"
            className={clsx(cls.action, cls.actionSave)}
            variant="outlined"
            disabled={readonly}
            alwaysEnable={readonly ? false : true}
          />
          <Button
            className={clsx(cls.action, cls.actionComplete)}
            variant="contained"
            color="secondary"
            onClick={completeAnalysis}
            disabled={readonly}
          >
            Complete analysis
          </Button>
          <Link to={toRisk}>
            <BlueOutlineButton className={clsx(cls.action, cls.actionBack)}>
              Go back
            </BlueOutlineButton>
          </Link>
        </TopToolbar>
        <ExportRisk
          creditId={risk.creditId}
          isOpened={isReportOpened}
          setOpened={setReportOpened}
        />
      </>
    );
  };

  if (showAnalysis) {
    return risk ? (
      <Edit {...props} title={<Title />} {...editProps}
        className={clsx(cls.page, 'details-page-wrapper')}>
        <SimpleForm toolbar={rbacEdit ? <AnalysisActions /> : false} className={cls.form}>
          {risk.isLegacy ? (
            <LegacyAnalysis risk={risk} />
          ) : (
            <Analysis risk={risk} readonly={readonly} />
          )}
        </SimpleForm>
      </Edit>
    ) : <Loader />;
  }

  const isFixed = risk?.isFixed || !!readonly;

  return (
    <InitialPage title="Risk summary" backTo="/credit-risk" backText="Back to all records">
      <Edit {...props} title={<Title />} {...editProps}>
        <SimpleForm toolbar={<EditActions />}>
          {risk && (
            <Row gutter={[20, 20]} className={cls.columns}>
              <Col sm={24} lg={12}>
                <RiskGeneral risk={risk} isFixed={isFixed} />
                <RiskCreditReport
                  risk={risk}
                  isPrepared={isReportPrepared}
                  reportNodeRef={reportNodeRef}
                />
                <RiskChat risk={risk} readonly={readonly} />
              </Col>
              <Col sm={24} lg={12}>
                <RiskProgressScore risk={risk} readonly={readonly} />
                <Terms risk={risk} readonly={readonly} />
                <Row gutter={[20, 20]}>
                  <Col sm={24} xl={12} className={cls.survey}>
                    <CreditSurveyProgress
                      credit={risk.credit}
                      link={`/credit/${risk.creditId}/?survey-view`}
                    >
                      <CreditSurveyView credit={risk.credit} hide />
                    </CreditSurveyProgress>
                  </Col>
                  <Col sm={24} xl={12} className={cls.analysis}>
                    <RiskTimeTracker risk={risk} />
                    {!isProdServer() && <RiskTapeFile risk={risk} forwardRef={reportNodeRef} />}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </SimpleForm>
      </Edit>
    </InitialPage>
  );
};

const useStyles = makeStyles({
  toolbar: {
    marginTop: 25,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button[aria-label="Save"]': {
      marginLeft: '-24px !important',
    },
  },
  columns: {
    width: '100%',
  },
  page: {
    padding: '30px 44px',
  },
  form: {
    padding: 0,
  },
  actions: {
    padding: '25px 0 0 0!important',
    minHeight: 'initial',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'transparent',
    gap: 25,
  },
  actionIcon: {
    marginRight: 10,
    fontSize: 18,
  },
  action: {
    width: '200px !important',
    height: '38px !important',
    borderRadius: '8px!important',
    fontWeight: '400 !important',
    lineHeight: 'normal',
  },
  actionSave: {
    color: '#FFF !important',
    background: '#042E6B !important',
  },
  actionComplete: {},
  actionBack: {},
  analysis: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    minHeight: 369,
    justifyContent: 'space-between',
  },
  survey: {
    minHeight: 369,
  },
});
