import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import { InfoTooltip, InitialCard } from '@components';
import { InvestorReports, reportsHttp } from '@network';
import cls from './InvestorReportProgress.module.css';

interface ItemProps {
  id: number;
  title: string;
  valueKey: string;
  tooltip?: string;
}

const blocks: ItemProps[] = [
  {
    id: 1,
    title: 'Registered Users',
    valueKey: 'registeredUsers',
    tooltip: 'Registered Users filtered by “Registration Date”',
  },
  {
    id: 2,
    title: 'Registered Users (Women)',
    valueKey: 'registeredUsersWomen',
    tooltip: 'Registered Users filtered by “Registration Date”',
  },
  {
    id: 3,
    title: 'Active Users',
    valueKey: 'activeUsers',
    tooltip: 'Registered Users with status “Active” filtered by “Registration Date”',
  },
  {
    id: 4,
    title: 'Active Users (Women)',
    valueKey: 'activeUsersWomen',
    tooltip: 'Registered Users with status “Active” filtered by “Registration Date”',
  },
  {
    id: 5,
    title: 'Active Credits',
    valueKey: 'activeCredits',
    tooltip: 'Credits with status: Signed + Delay + Restructure, filtered by “Last status change”',
  },
  {
    id: 6,
    title: 'Active Credits (Women)',
    valueKey: 'activeCreditsWomen',
    tooltip: 'Credits with status: Signed + Delay + Restructure, filtered by “Last status change”',
  },
  {
    id: 7,
    title: 'Partners',
    valueKey: 'partners',
    tooltip: 'B2BManagers (empresas, negocios, socios) filtered by “Registration Date”',
  },
  {
    id: 8,
    title: 'Partners (Women)',
    valueKey: 'partnersWomen',
    tooltip: 'B2BManagers (empresas, negocios, socios) filtered by “Registration Date”',
  },
];

export const InvestorReportProgress = ({ range }: {range: string}) => {
  const [data, setData] = useState<InvestorReports | null>(null);

  const loadData = async () => {
    try {
      const res: InvestorReports = await reportsHttp.investorReport({ range });
      setData(res);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadData();
  }, [range]);

  return (
    <InitialCard left="Investor Reports - Progress" extraPadding autoHeight mt={22}>
      <div className={cls.wrap}>
        <Row gutter={40} className={cls.row}>
          {blocks.map(b => (
            <Col xs={12} sm={12} key={b.id} className={cls.col}>
              <div className={cls.title}>
                <span className={cls.titleText}>{b.title}</span>
                {b.tooltip ? <InfoTooltip title={b.tooltip} className={cls.tooltip} /> : null}
              </div>
              <div className={cls.value}>{data && data[b.valueKey] ? data[b.valueKey] : 0}</div>
            </Col>
          ))}
        </Row>
      </div>
    </InitialCard>
  );
};
