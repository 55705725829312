import React, { useEffect, useLayoutEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';

import { NewRegistrationReport, reportsHttp } from '@network';
import { InitialCard } from '@components';

interface Props {
  range: string;
  type: string;
}

const colors = {
  registered: '#002A77',
};
type Field = keyof typeof colors;

export const NewRegistrationsReport = ({ range, type }: Props) => {
  const [numbers, setNumbers] = useState<NewRegistrationReport[]>([]);
  const cls = useStyles();
  const id = 'new-registrations-report';

  const loadNumbers = async () => {
    try {
      const nextNumbers = await reportsHttp.newRegistrationsReport({ range, type });
      setNumbers(nextNumbers);
    } catch (e) {}
  };

  useEffect(() => void loadNumbers(), [range, type]);

  useLayoutEffect(() => {
    const root = am5.Root.new(id);
    root.setThemes([
      am5themes_Animated.new(root),
    ]);

    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      paddingLeft: 0,
      wheelX: 'panX',
      wheelY: 'zoomX',
      layout: root.verticalLayout,
    }));

    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      }),
    );

    const xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      minorGridEnabled: true,
    } as any);

    const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: 'period',
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {}),
    }));

    xRenderer.grid.template.setAll({ location: 1 } as any);

    xAxis.get('renderer').labels.template.setAll({
      oversizedBehavior: 'wrap',
      textAlign: 'center',
      fontSize: 12,
      paddingTop: 10,
      paddingBottom: 10,
    });
    xAxis.data.setAll(numbers);

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1,
      }),
    }));

    function setColor(fieldName: Field) {
      const color = colors[fieldName];
      return am5.color(color);
    }

    function makeSeries(name: string, fieldName: Field) {
      const series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: fieldName,
        categoryXField: 'period',
        fill: setColor(fieldName),
        stroke: setColor(fieldName),
      }));

      series.columns.template.setAll({
        tooltipText: '{name}: {valueY}',
        width: am5.percent(90),
        tooltipY: 0,
        strokeOpacity: 0,
      });

      series.data.setAll(numbers);
      series.appear();
      legend.data.push(series);
    }

    makeSeries('New Registrations', 'registered');

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [id, numbers]);
  const height = numbers && numbers.length > 0 ? 350 : 50;

  return (
    <InitialCard left="New Registrations"
      extraPadding autoHeight contentClass={cls.content} mt={22}>
      <div id={id} className={cls.chart} style={{ height, marginBottom: -45 }} />
    </InitialCard>
  );
};

const useStyles = makeStyles({
  content: {
    paddingTop: 30,
    paddingBottom: 17,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chart: {
    width: '100%',
    height: 350,
  },
});
