import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import dayjs from 'dayjs';
import { makeStyles } from '@mui/styles';

import { InitialCard } from '@components';
import { creditGoalHttp } from '@network/credit-goal-http';
import { GoalsForm } from './GoalsForm';

const generateInitialGoals = () => {
  return Array.from({ length: 12 }, (_, month) => ({
    allocation: 0,
    revenue: 0,
    month: month,
  }));
};

export type GoalsViewType = 'view' | 'edit' | 'create';

export interface MonthData {
  allocation: number;
  revenue: number;
  month: number;
}

export type QuarterlyData = MonthData[][];

interface Props {
  setUpdated?: () => void;
}

export const AllocationRevenueGoals = ({ setUpdated }: Props) => {
  const cls = useStyles();

  const [currentYear, setCurrentYear] = useState<string>('');
  const [viewType, setViewType] = useState<GoalsViewType>('view');
  const [availableYears, setAvailableYears] = useState<Record<string, MonthData[]>>({});
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const hideModal = async () => {
    const nextYear = (dayjs(`${currentYear}-01-01`).subtract(1, 'year').year()).toString();

    if (viewType === 'create') {
      delete availableYears[currentYear];
      setCurrentYear(nextYear);
    }

    setIsModalVisible(false);

    const nextAvailableYears = await creditGoalHttp.findAll();

    if (nextAvailableYears) {
      setAvailableYears(nextAvailableYears);
    }

    setViewType('view');
  };

  const editGoalModal = () => {
    setIsModalVisible(true);
    setViewType('edit');
  };

  const addNewGoalModal = () => {
    const lastYear = Object.keys(availableYears).pop();

    const nextYear = (dayjs(`${lastYear || dayjs().year()}-01-01`).add(1, 'year').year()).toString();

    availableYears[nextYear] = generateInitialGoals();
    setIsModalVisible(true);
    setCurrentYear(nextYear);
    setViewType('create');
  };

  useEffect(() => {
    const year = (dayjs().year()).toString();
    setCurrentYear(year);

    (async () => {
      const nextAvailableYears = await creditGoalHttp.findAll();

      if (nextAvailableYears) {
        setAvailableYears(nextAvailableYears);
      }
    })();
  }, []);

  const hasPreviousYear = !!availableYears[dayjs(`${currentYear}-01-01`).subtract(1, 'year').year()];
  const hasNextYear = !!availableYears[dayjs(`${currentYear}-01-01`).add(1, 'year').year()];

  if (!currentYear) {
    return null;
  }

  return (
    <InitialCard left="Credit Allocation And Revenue Goals" extraPadding autoHeight mt={22}>
      <GoalsForm
        goals={availableYears[currentYear]}
        currentYear={currentYear}
        setCurrentYear={setCurrentYear}
        type={viewType}
        editGoalModal={editGoalModal}
        addNewGoalModal={addNewGoalModal}
        hideModal={hideModal}
        hasPreviousYear={hasPreviousYear}
        hasNextYear={hasNextYear}
      />

      <Modal
        open={isModalVisible}
        title={false}
        onCancel={hideModal}
        width={1280}
        closeIcon={null}
        className={cls.modal}
        footer={null}
        centered
      >
        <div className={cls.modalTitle}>Credit Allocation And Revenue Goals</div>
        <GoalsForm
          goals={availableYears[currentYear]}
          currentYear={currentYear}
          hideModal={hideModal}
          type={viewType}
          setUpdated={setUpdated}
        />
      </Modal>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  modal: {
    top: 20,
  },
  modalTitle: {
    fontSize: 20,
    lineHeight: '22px',
    fontWeight: 600,
    display: 'inline-block',
  },
});
