import { useEffect, useRef, useState } from 'react';
import {
  ListProps,
  TextField,
  ReferenceField,
  RowClickFunction,
  Identifier,
  RaRecord,
} from 'react-admin';
import { useSearchParams } from 'react-router-dom';

import {
  TextInput,
  Grid,
  GridList,
  Forbidden,
  SelectArrayInput,
  SelectInput,
} from '@components';
import { dealerHttp } from '@network';
import { financeSettingsHttp } from '@network/finance-settings-http';
import { Dealer, dealerTiersChoices } from '@types';
import { useSettingsContext } from '@providers';
import { useExporter, useRbacEdit, useRbacList } from '@hooks';
import { BuyerCreator } from '../BuyerCreator';
import { MergeDealersForm } from '../MergeDealersForm';
import cls from './DealerList.module.css';

export const DealerList = (props: ListProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dealerId = searchParams.get('dealerId');

  const rbacList = useRbacList();
  const rbacEdit = useRbacEdit();
  const {
    isBuyersCreatorActive, setIsBuyersCreatorActive, setIsToolbarShown,
  } = useSettingsContext();
  const modalType = useRef<'create' | 'edit'>('create');
  const [selectedBuyer, setSelectedBuyer] = useState<Dealer | null>(null);
  const [activationOptions, setActivationOptions] = useState<{id: number; name: string}[]>([]);

  const title = 'Buyers';

  const exporter = useExporter({
    filename: `Verqor ${title}`,
    props: {
      'id': {
        label: 'ID',
      },
      'activationTitle': {
        label: 'Activation',
      },
      'name': {
        label: 'Name',
      },
      'tier': {
        label: 'Tier',
      },
      'cropNamesString': {
        label: 'Unique Crops',
      },
      'priority': {
        label: 'Type',
      },
      'segment': {
        label: 'Segment',
      },
      'status': {
        label: 'Status',
      },
      'addedByUserFullName': {
        label: 'Added by User',
      },
      'owner.name': {
        label: 'Owner',
        prop: 'owner.name',
      },
    },
  });

  const openCreator = () => {
    modalType.current = 'create';
    setIsBuyersCreatorActive(true);
    setIsToolbarShown(false);
  };

  const closeCreator = () => {
    modalType.current = 'create';
    setIsBuyersCreatorActive(false);
    setIsToolbarShown(true);
    setSelectedBuyer(null);
  };

  const rowClick: RowClickFunction = (id: Identifier, resource: string, record: RaRecord) => {
    modalType.current = 'edit';
    setSelectedBuyer(record as Dealer);
    setIsBuyersCreatorActive(true);
    setIsToolbarShown(false);

    return false;
  };

  useEffect(() => {
    (async () => {
      try {
        const nextActivations = await financeSettingsHttp.getActivations();

        if (nextActivations) {
          setActivationOptions(nextActivations.map(activation => ({
            id: activation.id,
            name: activation.activation,
          })));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    if (dealerId) {
      (async () => {
        modalType.current = 'edit';
        const currentDealer = await dealerHttp.getById(+dealerId);

        setSelectedBuyer(currentDealer);
        setIsBuyersCreatorActive(true);

        searchParams.delete('dealerId');
        setSearchParams(searchParams);
      })();
    }
  }, [dealerId]);

  const filters = [
    <TextInput source="q" name="q" key="q" variant="outlined" label="Search"
      alwaysOn style={{ width: 300 }} inputProps={{ maxLength: 40 }}
    />,
    <SelectInput
      key="activationId"
      source="activationId"
      label="Activation"
      choices={activationOptions}
      className={'MS-field'}
    />,
    <TextInput source="addedBy" name="addedBy" key="addedBy"
      variant="outlined" label="Added by user" alwaysOn
      style={{ width: 300 }} inputProps={{ maxLength: 40 }}
    />,
    <SelectArrayInput
      key="tier"
      source="tier"
      choices={dealerTiersChoices}
      className="MS-field"
      style={{ width: 280 }}
      alwaysOn
    />,
  ];

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <>
      <GridList {...props}
        filters={filters}
        sort={{ field: 'id', order: 'DESC' }}
        customCreate={openCreator}
        className={cls.list}
        exporter={exporter}
      >
        <Grid
          rowClick={rowClick}
          className={cls.grid}
          defaultColumns={'id name tier cropNamesString phone priority segment status merge'.split(' ')}
        >
          <TextField source="id" name="id" />
          <TextField source="activationTitle" label="Activation" cellClassName={cls.nameCell} />
          <TextField source="name" name="name" cellClassName={cls.nameCell} />
          <TextField source="tier" name="Tier" />
          <TextField source="cropNamesString" label="Unique Crops" sortable={false} />
          <TextField source="priority" label="Type" />
          <TextField source="segment" label="Segment" />
          <TextField source="status" label="Status" />
          <TextField source="addedByUserFullName" label="Added by User" />
          <TextField source="owner.name" label="Owner" />
          {rbacEdit ? <MergeDealersForm source="merge" label="Merge" /> : null}
        </Grid>
      </GridList>

      {isBuyersCreatorActive ? (
        <BuyerCreator
          type={modalType.current}
          close={closeCreator}
          values={selectedBuyer}
        />
      ) : null}
    </>
  );
};
