import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

import { Credit, SurveyQuestion } from '@types';
import { InitialCard, InitialPage, Loader } from '@components';
import { SurveyTableAnswer } from './SurveyTableAnswer';
import { SurveyCustomAnswer } from './SurveyCustomAnswer';
import { SurveyMultipleAnswer } from './SurveyMultipleAnswer';
import cls from './CreditSurveyView.module.css';
import { Button } from '@mui/material';
import { surveyHttp } from '@network';

interface Props {
  credit: Credit;
  hide?: boolean;
  btnText?: string | JSX.Element;
  btnClass?: string;
  loading?: boolean;
  cb?: () => void;
}

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none" className={cls.icon}>
    <g clipPath="url(#clip0_3272_16402)">
      <path d="M12.1043 3.58988H15.1268C15.0889 3.51969 15.0384 3.45628 14.9784 3.40244L11.8814 0.654641C11.7976 0.579665 11.6964 0.525028 11.5859 0.495117L11.6393 3.14959C11.6439 3.39287 11.8501 3.58828 12.1043 3.58988Z" fill="#042E6B"/>
      <path d="M12.1042 4.25C11.4758 4.24641 10.9646 3.76505 10.95 3.16404L10.8958 0.47168H1.55125C0.693334 0.474073 -0.000832584 1.14088 7.49515e-07 1.96203V18.1242C-0.000832584 18.9453 0.693334 19.6121 1.55125 19.6145H13.6571C14.515 19.6121 15.2092 18.9453 15.2083 18.1242V4.25H12.1042Z" fill="#042E6B"/>
      <path d="M10.3852 12.1448L8.55982 13.8868C8.35399 14.0831 8.08107 14.2007 7.79107 14.2183C7.76565 14.2214 7.74024 14.223 7.71482 14.2234C7.69274 14.2246 7.67065 14.2246 7.64857 14.2234C7.35815 14.2111 7.08274 14.0978 6.87315 13.9052L5.01732 12.3171C4.7569 12.0954 4.73357 11.7137 4.96524 11.4645C5.1969 11.2152 5.59565 11.1929 5.85607 11.4146L7.09065 12.4655V7.45245C7.07857 7.11905 7.35107 6.83908 7.69982 6.82752C8.04815 6.81595 8.34065 7.07677 8.35274 7.41058C8.35315 7.42454 8.35315 7.43849 8.35274 7.45245V12.3821L9.49649 11.2874C9.74399 11.0525 10.1436 11.0537 10.389 11.2906C10.6344 11.5275 10.6327 11.9099 10.3857 12.1448H10.3852Z" fill="#E1E5ED"/>
    </g>
    <defs>
      <clipPath id="clip0_3272_16402">
        <rect width="15.2083" height="19.1429" fill="white" transform="translate(0 0.47168)"/>
      </clipPath>
    </defs>
  </svg>
);

const Header = ({ code, name }: { code: string, name: string }) => (
  <div className={cls.header}>
    <div className={cls.headerRow}>
      <div className={cls.headerCol}>
        <div className={cls.headerLogo}/>
        <div className={cls.headerTitle}>Aplicación de crédito</div>
      </div>
      <div className={cls.headerCol}>
        <div className={cls.headerLine}>
          <div className={cls.headerLineLabel}>Credit code:</div>
          <div className={cls.headerLineValue}>{code}</div>
        </div>
        <div className={cls.headerLine}>
          <div className={cls.headerLineLabel}>Accredited:</div>
          <div className={cls.headerLineValue}>{name}</div>
        </div>
      </div>
    </div>
  </div>
);

export const CreditSurveyView = (
  { credit, hide, btnText, btnClass, loading, cb }: Props,
) => {
  const [isExporting, setExporting] = useState(false);
  const [questions, setQuestions] = useState<SurveyQuestion[]>([]);
  const pdfFilename = `credit_${credit?.id}_risk_survey.pdf`;

  const getSurvey = async () => {
    if (credit) {
      const {
        surveyQuestions, answeredDate: loadedAnsweredDate, isFixed,
      } = await surveyHttp.getCreditSurvey(credit.id);

      setQuestions(surveyQuestions.map((q, index) => {
        q.position = index + 1;
        return q;
      }));
    }
  };

  const exportPdf = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    setExporting(true);
    await new Promise((resolve) => setTimeout(resolve, 250));
    const div = document.getElementById('export-survey');

    try {
      div && html2canvas(div).then((canvas) => {
        const imgWidth = 212;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        heightLeft -= pageHeight;
        const doc = new jsPDF('p', 'mm');
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
          heightLeft -= pageHeight;
        }

        doc.save(pdfFilename);
      });
    } finally {
      cb && cb();
      setExporting(false);
    }
  };

  useEffect(() => {
    getSurvey();
  }, []);

  return (
    <>
      <InitialPage
        title="Risk Survey"
        backTo="/credit-risk"
        backText="Back to all risk managers"
        className={clsx(cls.initialPage, hide && cls.hide)}
        headerRight={
          <Button
            variant="outlined"
            color="inherit"
            className={cls.exportBtn}
            onClick={isExporting ? undefined : exportPdf}
          >{isExporting ? <Loader mini /> : <Icon />}Export</Button>
        }
      >
        <InitialCard fullHeight boxClass={cls.box} wrapperClass={cls.wrap} id="export-survey">
          <div className={cls.pdfWrap}>
            <Header code={credit.code} name={credit.accreditedName || '---'} />
            <div className={cls.body}>
              {questions?.length
                ? (<div className={cls.bodyRow}>
                  {questions.sort(q => q.position).map(q => (
                    <div className={clsx(
                      cls.bodyCol,
                      q.isTable && cls.table,
                      q.isMultiple && cls.multiple,
                      q.isNumber && cls.number,
                      q.isMoney && cls.noney,
                    )} key={q.position}>
                      <div className={cls.questionHeader}>
                        <div className={cls.questionHeaderCnt}>{q.position}</div>
                        <div className={cls.questionHeaderTitle}>{q.nameMx}</div>
                      </div>
                      {q.isTable ? <SurveyTableAnswer value={q}/> : null}
                      {q.isMultiple ? <SurveyMultipleAnswer value={q} /> : null}
                      {!(q.isTable || q.isMultiple) ? <SurveyCustomAnswer value={q} /> : null}
                    </div>
                  ))}
                </div>) : <div className={cls.empty}>Questions list is empty</div>}
            </div>
          </div>
        </InitialCard>
      </InitialPage>
      <Button
        variant="outlined"
        color="inherit"
        className={clsx(cls.secondExportBtn, !hide && cls.hide, btnClass)}
        onClick={isExporting ? undefined : exportPdf}
      >
        {btnText ? btnText : (
          isExporting || loading ? <Loader mini className={cls.icon} /> : <>
            <Icon />Export
          </>
        )}
      </Button>
    </>
  );
};
