import React, { useEffect, useMemo, useState } from 'react';
import { clsx } from 'clsx';

import { Credit, Doctype } from '@types';
import { doctypeHttp } from '@network';
import { BlueButton, InitialPage } from '@components';
import { DoctypeDocument } from '@pages/credit/DoctypeDocument';
import cls from './credit-contracts-details.module.css';

interface CreditContractsDetailsProps {
  credit: Credit;
  setCredit: any;
  isSuperadmin: boolean;
}

export const CreditContractsDetails = ({
  credit, 
  setCredit,
  isSuperadmin,
}: CreditContractsDetailsProps) => {
  const [doctypes, setDoctypes] = useState<Doctype[]>([]);
    
  const backTo = `/credit/${credit.id}?initial`;

  const retentionLetterDoctype = useMemo(() => {
    return doctypes.find(doctype => doctype.isRetentionLetter);
  }, [doctypes]);

  const amortizationTableDoctype = useMemo(() => {
    return doctypes.find(doctype => doctype.isAmortizationTable);
  }, [doctypes]);

  const creditContractDoctype = useMemo(() => {
    return doctypes.find(doctype => doctype.isCreditContract);
  }, [doctypes]);

  useEffect(() => {
    credit && (async () => {
      const res = await doctypeHttp.getAll();
      if (res) {
        setDoctypes(res);
      }
    })();
  }, [credit]);

  return (
    <InitialPage title="Contracts" backTo={backTo} backText="Back to credit">
      <div className={cls._}>
        {retentionLetterDoctype && (
          <div className={cls.item}>
            <div className={cls.content}>
              <DoctypeDocument
                doctype={retentionLetterDoctype}
                credit={credit}
                setCredit={setCredit}
                isSuperadmin={isSuperadmin}
                editable={true}
              />
            </div>
          </div>
        )}

        {amortizationTableDoctype && (
          <div className={cls.item}>
            <div className={cls.content}>
              <DoctypeDocument
                doctype={amortizationTableDoctype}
                credit={credit}
                setCredit={setCredit}
                isSuperadmin={isSuperadmin}
                editable={true}
              />
            </div>
          </div>
        )}

        {creditContractDoctype && (
          <div className={clsx(cls.item, cls.fullWidth)}>
            <div className={cls.content}>
              <div className={cls.contract}>
                <DoctypeDocument
                  doctype={creditContractDoctype}
                  credit={credit}
                  setCredit={setCredit}
                  isSuperadmin={isSuperadmin}
                  editable={true}
                  creditContractDoctype={creditContractDoctype}
                />
              </div>
              <div className={cls.integration}>
                <div className={cls.section}>
                  <div className={cls.section_content}>
                    Here we will have the cincel integration
                  </div>
                  <div className={cls.actions}>
                    <BlueButton>Generate Contract</BlueButton>
                    <BlueButton>Sign Contract</BlueButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </InitialPage>
  );
};
