import { clsx } from 'clsx';
import { makeStyles } from '@mui/styles';
import { Button, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { PropsWithChildren } from 'react';
import { CreateButton, FilterForm, ListBase, Pagination } from 'react-admin';
import { ListProps, useResourceContext, useResourceDefinitions } from 'react-admin';

import { useCreateAccess } from '@hooks';
import { ModalProvider } from '@providers';
import { GridActions } from './GridActions';
import { FilterButton } from './FilterButton';
import { CustomExportButton } from './CustomExportButton';
import { OptionsButton } from '@components/GridList/OptionsButton';
import './filters.css';

type Props = ListProps & {
  filters: JSX.Element[];
  className?: string;
  hideRemoveButton?: boolean;
  customCreate?: (arg?: any) => void;
  editComponent?: JSX.Element;
}

const CustomFilterButton = ({ filters, className }: {filters: any[], className?: string}) => {
  const moreFiltersTotal = filters.filter(f => !f.props?.alwaysOn).length;

  if (moreFiltersTotal === 0) {
    return null;
  }

  return (
    <FilterButton filters={filters} className={className} />
  );
};

export const GridList = (props: PropsWithChildren<Props>) => {
  const { filters, className, hideRemoveButton, customCreate, editComponent } = props;
  const children = props.children as any;
  const cls = useStyles();
  const resource = useResourceContext();
  const resourcesByKey: any = useResourceDefinitions();

  const hasCreatePage = resourcesByKey && resourcesByKey[resource]
    && resourcesByKey[resource].hasCreate;
  const hasCreateAccess = useCreateAccess(resource);
  const showCreateButton = hasCreatePage && hasCreateAccess && !customCreate;

  return (
    <div className={clsx(className, 'RaList-main')}>
      <ListBase resource={resource} exporter={props.exporter} filter={props.filter}
        perPage={props.perPage} sort={props.sort} debounce={props.debounce}>
        <Stack
          direction="row"
          className={clsx('MuiToolbar-root', 'RaFilter-form', 'css-RaTopToolbar-root', cls.header)}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            className={cls.filters}
          >
            <FilterForm filters={filters} />
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            className={cls.buttons}
          >
            <OptionsButton />
            <CustomFilterButton filters={filters} className={cls.filtersBtn} />
            {showCreateButton && (
              <CreateButton />
            )}
            {customCreate && (
              <Button variant="outlined"
                onClick={customCreate}
                startIcon={<AddIcon />}
                style={{ lineHeight: 1.5 }}>
                Create
              </Button>
            )}
            <CustomExportButton
              exporter={props.exporter as any}
              columns={children?.props?.children || []}
            />
          </Stack>
        </Stack>
        <div className={clsx('list-page', cls.listPage)}>
          <ModalProvider>
            <GridActions editComponent={editComponent} hideRemoveButton={hideRemoveButton} />
          </ModalProvider>
          {children}
        </div>
        <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
      </ListBase>
    </div>
  );
};

const useStyles = makeStyles({
  header: {
    padding: '10px 0 0',
    maxWidth: 'calc(100vw - 290px)',
    justifyContent: 'space-between',
  },
  filters: {
    flex: '0 1 100%',
  },
  buttons: {
    paddingTop: 19,
    '& > *': {
      marginLeft: 15,
    },
  },
  filtersBtn: {},
  listPage: {
    position: 'relative',
  },
});
