import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-admin';
import { Button } from '@mui/material';

import { RangeTypeParams, reportsHttp } from '@network';
import { dateFormatter, querySerialize } from '@utils';
import { InitialCard } from '@components';

type ReportKey = 'draft' | 'moderation' | 'completed_file' | 'bank_revision' | 'approved'
  | 'signed' | 'repaid' | 'restructure' | 'delay' | 'denied';

interface Report {
  key: ReportKey;
  title: string;
  link: string;
  bgColor: string;
  color?: string;
}

export const CreditsStatusesReport = ({ range, type }: RangeTypeParams) => {
  const [numbers, setNumbers] = useState<Record<string, string | number>>({});
  const cls = useStyles();

  const loadNumbers = async () => {
    try {
      const nextNumbers = await reportsHttp.creditsStatuses({ range, type });
      setNumbers(nextNumbers);
    } catch (e) {}
  };

  useEffect(() => void loadNumbers(), [range, type]);

  const reports: Report[] = [
    {
      key: 'moderation', title: 'Applying', bgColor: '#2196F3',
      link: '/credit?' + querySerialize({ filter: JSON.stringify({ status: ['moderation'] }) }),
    },
    {
      key: 'completed_file', title: 'Completed File', bgColor: '#002A77',
      link: '/credit?' + querySerialize({ filter: JSON.stringify({ status: ['completed_file'] }) }),
    },
    {
      key: 'bank_revision', title: 'Analysis', bgColor: '#009688',
      link: '/credit?' + querySerialize({ filter: JSON.stringify({ status: ['bank_revision'] }) }),
    },
    {
      key: 'approved', title: 'Approved', bgColor: '#007002',
      link: '/credit?' + querySerialize({ filter: JSON.stringify({ status: ['approved'] }) }),
    },
    {
      key: 'denied', title: 'Denied', bgColor: '#F22',
      link: '/credit?' + querySerialize({ filter: JSON.stringify({ status: ['denied'] }) }),
    },
    {
      key: 'signed', title: 'Signed', bgColor: '#4CAF50',
      link: '/credit?' + querySerialize({ filter: JSON.stringify({ status: ['signed'] }) }),
    },
    {
      key: 'restructure', title: 'Restructure', bgColor: '#673AB7',
      link: '/credit?' + querySerialize({ filter: JSON.stringify({ status: ['restructure'] }) }),
    },
    {
      key: 'delay', title: 'Delay', bgColor: '#CDDC39', color: '#000',
      link: '/credit?' + querySerialize({ filter: JSON.stringify({ status: ['delay'] }) }),
    },
    {
      key: 'repaid', title: 'Repaid', bgColor: '#3F51B5',
      link: '/credit?' + querySerialize({ filter: JSON.stringify({ status: ['repaid'] }) }),
    },
  ];
  const diffText = numbers.slice && numbers.type
    ? `Progress of ${numbers.type} since ${dateFormatter.toDateSlash(numbers.slice.toString())}`
    : undefined;

  return (
    <InitialCard left="Current Pipeline" extraPadding autoHeight mt={22} right={diffText}>
      <br />
      <div className={cls.row}>
        {reports.map(report => {
          const sliceKey = `${report.key}_slice`;
          const diff = (+numbers[report.key] || 0) - (+numbers[sliceKey] || 0);
          return (
            <div className={cls.col} key={report.key}>
              <Link to={report.link}>
                <Button className={cls.btn}>
                  <div className={cls.box} style={{
                    backgroundColor: report.bgColor,
                    color: report.color || 'white',
                  }}>
                    <div className={cls.title}>
                      {report.title}
                    </div>
                    <div className={cls.number}>
                      {+numbers[report.key] || 0}
                    </div>
                    <div className={cls.diff}>
                      {diff === 0 && <span>0</span>}
                      {diff > 0 && <span className={cls.diffPositive}>+{diff}</span>}
                      {diff < 0 && <span className={cls.diffNegative}>{diff}</span>}
                    </div>
                  </div>
                </Button>
              </Link>
            </div>
          );
        })}
      </div>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  row: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: -10,
  },
  col: {
    width: '20%',
    padding: 10,
  },
  diff: {
    position: 'absolute',
    right: 8,
    bottom: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: 'white',
    fontSize: 13,
    fontWeight: 500,
    color: '#000',
  },
  diffPositive: {
    color: '#4CAF50',
  },
  diffNegative: {
    color: '#F22',
  },
  btn: {
    padding: 0,
    textTransform: 'none',
    display: 'block',
    width: '100%',
  },
  box: {
    padding: '10px 2px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#FFF',
  },
  title: {
    fontSize: 15,
    fontWeight: 400,
  },
  number: {
    marginTop: 5,
    fontSize: 22,
    fontWeight: 700,
  },
});
