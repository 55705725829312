import React, { useEffect, useState } from 'react';
import { TextInputProps } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { useController } from 'react-hook-form';
import { DatePicker, Select } from 'antd';
import moment, { Moment } from 'moment-timezone';
import IMask from 'imask';
import { clsx } from 'clsx';

const { RangePicker } = DatePicker;
const DATE_FORMAT = 'DD/MM/YYYY';
const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: new Date().getFullYear() },
  },
  format: (date: Date) => moment(date).format(DATE_FORMAT),
  mask: Date,
  parse: (date: string) => moment(date, DATE_FORMAT),
  pattern: DATE_FORMAT,
});

interface ExtraProps {
  choices: {id: string, name: string}[];
}

export const DateRangeSelect = (props: TextInputProps & ExtraProps) => {
  const cls = useStyles();
  const {
    field: { value, onChange: setRange },
  } = useController({ name: props.source });
  const [prop, setProp] = useState(props.choices[0]?.id);
  const [propStart, end] = (value || '').split('-');
  const [, start] = propStart.split(':');

  useEffect(() => {
    if (value) {
      const [propStart] = (value || '').split('-');
      const [nextProp] = propStart.includes(':') ? propStart.split(':') : [props.choices[0]?.id];
      nextProp && prop !== nextProp && setProp(nextProp);
    }
  }, [value]);

  useEffect(() => {
    if (value) {
      const [propStart, end] = (value || '').split('-');
      const [, start] = propStart.split(':');
      setRange(`${prop}:${start}${end ? `-${end}` : ''}`);
    }
  }, [prop]);

  const handleChange = (range: any) => {
    const dateStart: Moment = range && range[0];
    const dateEnd: Moment = range && range[1];
    const nextValues: string[] = [];
    if (dateStart) {
      nextValues.push(dateStart.format('DD.MM.YYYY'));
    }
    if (dateEnd) {
      nextValues.push(dateEnd.format('DD.MM.YYYY'));
    }
    const nextRange = prop + ':' + nextValues.join('-');
    setRange(nextRange);
  };

  return (
    <div>
      <label className={cls.label}>
        <span>{props.label}</span>
      </label>
      <div className={clsx('MuiInputBase-root', cls.box)}>
        <div className={cls.selectBox}>
          <Select value={prop} onChange={v => setProp(v)} className={cls.select}>
            {props.choices.map(choice => (
              <Select.Option value={choice.id} key={choice.name}>
                {choice.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={cls.rangeBox}>
          <RangePicker
            onChange={handleChange}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              const input = event.target as HTMLInputElement;
              input.value = MASKED.resolve(input.value);
            }}
            defaultValue={[
              moment(start, DATE_FORMAT).isValid() ? moment(start, DATE_FORMAT) : null,
              moment(end, DATE_FORMAT).isValid() ? moment(end, DATE_FORMAT) : null,
            ]}
            format={DATE_FORMAT}
            placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
            allowEmpty={[true, true]}
          />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  label: {
    display: 'block',
    color: '#042E6B',
    fontSize: 12,
    lineHeight: 1,
    marginTop: 4,
    marginLeft: 14,
    marginBottom: 3,
  },
  selectBox: {
    minWidth: 120,
    background: '#042E6B',
  },
  select: {
    height: 35,
    lineHeight: 32,
    background: 'transparent !important',
    '& .ant-select-selector': {
      height: 35,
      lineHeight: '32px !important',
      border: 'none!important',
      boxShadow: 'none!important',
    },
    '& .ant-select-selection-item': {
      height: 35,
      lineHeight: '32px !important',
    },
    '& *': {
      background: 'transparent !important',
      color: 'white !important',
    },
  },
  rangeBox: {
    paddingTop: 8,
    paddingRight: 5,
    width: 240,
  },
  box: {
    display: 'flex',
    backgroundColor: 'white',
    overflow: 'hidden',
    padding: '0',

    '& .ant-picker-range': {
      border: 'none',
      boxShadow: 'none',
    },

    '& .ant-picker-separator': {
      fontSize: 18,
      color: '#002A77',
    },

    '& .ant-picker-suffix': {
      fontSize: 16,
      color: '#002A77',
    },

    '& .ant-picker-input': {
      border: 'none !important',
    },

    '& .ant-picker-input > input': {
      border: 'none !important',
      padding: '7px !important',
    },

    '& .ant-picker-input > input::placeholder': {
      color: 'rgba(0, 42, 119, 0.50)',
      fontFamily: 'Outfit, sans-serif',
      fontSize: 15,
      fontWeight: 400,
      lineHeight: 'normal',
    },

    '& .ant-picker-range .ant-picker-active-bar': {
      height: 1,
      width: '75px !important',
      backgroundColor: '#002A77',
      bottom: -2,
      marginLeft: 7,
    },

    '& .ant-picker-range .ant-picker-clear': {
      right: -3,
      fontSize: 23,
    },
  },
});
