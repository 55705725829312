import { PropsWithChildren } from 'react';
import { Link } from 'react-admin';

import './initial-page-content.css';
import cls from './initial-page.module.css';
import { clsx } from 'clsx';

interface Props {
  title: string;
  backTo?: string;
  backText?: string;
  gap?: number;
  headerRight?: JSX.Element;
  className?: string;
}

export const InitialPage = ({
  children, title, backTo, backText, gap, headerRight, className,
}: PropsWithChildren<Props>) => {
  return (
    <div className={clsx(cls.wrap, className)}>
      <div className={cls.header}>
        <div className="ip-header-left">
          <h1 className={cls.title}>{title}</h1>
          {backTo ? <Link to={backTo} className={cls.linkBack}>
            <svg xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="8"
              viewBox="0 0 5 8"
              fill="none">
              <path d="M0.000493667 4.01724C0.00412218 4.15392 0.0649694 4.28444 0.171313 4.38369L3.92263 7.8315C4.0781 7.97311 4.30725 8.03123 4.52412 7.9837C4.741 7.93617 4.91293 7.79013 4.97517 7.60075C5.03742 7.41136 4.98076 7.20696 4.82613 7.06437L1.49013 4L4.82613 0.935633C4.98076 0.793041 5.03742 0.588636 4.97517 0.399252C4.91293 0.209623 4.741 0.0638297 4.52412 0.016299C4.30725 -0.0312311 4.0781 0.0268887 3.92263 0.168495L0.171312 3.61631C0.0554795 3.72417 -0.00620512 3.86874 0.000493667 4.01699L0.000493667 4.01724Z"
                fill="#002A77" />
            </svg>
            <span className={cls.linkBackText}>{backText}</span>
          </Link> : null}
        </div>
        {headerRight && (
          <div className="ip-header-right">{headerRight}</div>
        )}
      </div>
      <div className="InitialPageContent" style={{ marginTop: gap }}>
        {children}
      </div>
    </div>
  );
};
