import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { InputNumber, Input, DatePicker, Select } from 'antd';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { DollarCircleOutlined, PlusOutlined, DeleteOutlined, LockFilled } from '@ant-design/icons';
import moment from 'moment-timezone';
import IMask from 'imask';
import IconButton from '@mui/material/IconButton';

import { CreditMoney, CreditMoneyType } from '@types';
import { BlueOutlineButton, ConfirmDelete } from '@components';
import { moneyFormatter } from '@utils';

interface MoneyProps {
  money: CreditMoney[];
  group: CreditMoneyType;
  moneyLocked?: CreditMoney[];
  setMoney: (nextMoney: CreditMoney[]) => void;
  editable: boolean;
}

export const MoneyWidget = ({ money, moneyLocked, group, setMoney, editable }: MoneyProps) => {
  const cls = useStyles();
  const notify = useNotify();
  const [search, setSearch] = useState<string>('');

  const moneyFixed = moneyLocked || [];
  const summary = [...money, ...moneyFixed]
    .reduce((acc, cur) => acc + +(cur.amount || 0), 0);

  // add/delete row actions
  const addRow = (type: CreditMoneyType) => {
    if (money.length >= MAX_ROWS) {
      notify(`Limit is ${MAX_ROWS} rows`);
      return;
    }
    const newRow: CreditMoney = {
      key: (new Date()).getTime(),
      type,
      title: `Pago ${money.length + 1}`,
      subtitle: '',
      amount: 0,
      date: moment(new Date()) as any,
    };
    setMoney([...money, newRow]);
  };

  const onDelete = (id: number) => {
    const nextMoney = money.filter(creditMoney => creditMoney.id !== id);
    setMoney(nextMoney);
  };

  // update row field
  const onChange = (id: number, field: string, value: any) => {
    const nextMoney = money.map(creditMoney => {
      if ((creditMoney.id === id || creditMoney.key === id) && !creditMoney.isLocked) {
        creditMoney[field] = value;
      }
      return creditMoney;
    });
    setMoney(nextMoney);
  };

  // columns
  let columns: string[] = ['Concept', 'Amount', 'Date'];

  if (group === 'credit_fee') {
    columns = ['Concept', 'Cash/external invoice', 'Amount', 'Date'];
  } else if (group === 'insurance') {
    columns = ['Policy number', 'Amount', 'Date'];
  }

  return (
    <div className="RaDatagrid-tableWrapper">
      {/* FILTERS AND ACTIONS */}
      <div className={cls.tableHeader}>
        <div>
          <Input
            placeholder="Search"
            className={cls.searchInput}
            value={search}
            onChange={e => setSearch(e.target.value)}
            suffix={(
              <svg xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none">
                <path d="M14.3632 15C14.1941 15 14.0348 14.9338 13.9147 14.8137L10.2308 11.1397L9.99064 11.3162C8.90485 12.1176 7.61809 12.5417 6.2676 12.5417C4.91711 12.5417 3.63034 12.1176 2.54456 11.3162C-0.237305 9.26471 -0.830443 5.33088 1.22348 2.54902C2.39995 0.953431 4.2872 0 6.2725 0C7.61809 0 8.9024 0.42402 9.99064 1.22549C12.7725 3.27696 13.3681 7.21078 11.3142 9.99265L11.1377 10.2328L14.8117 13.9167C15.0593 14.1642 15.0593 14.5662 14.8117 14.8137C14.6916 14.9338 14.5323 15 14.3632 15ZM6.27495 1.27206C3.51515 1.27206 1.27005 3.51716 1.27005 6.27696C1.27005 9.03677 3.51515 11.2819 6.27495 11.2819C9.03475 11.2819 11.2799 9.03677 11.2799 6.27696C11.2799 3.51716 9.03475 1.27206 6.27495 1.27206Z"
                  fill="#002A77" />
              </svg>
            )}
          />
        </div>
        {editable ? (
          <div>
            <BlueOutlineButton onClick={() => addRow(group)} className={cls.addBtn}>
              <PlusOutlined /> Add row
            </BlueOutlineButton>
          </div>
        ) : null}
      </div>

      {/* TABLE ROWS */}
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="user-orders-list">
          <TableHead>
            <TableRow>
              {columns.map(columnName => (
                <TableCell key={columnName} className={cls.thead}>
                  {columnName}
                </TableCell>
              ))}
              {editable && <TableCell key="delete" className={cls.deleteCol}>{' '}</TableCell>}
            </TableRow>
          </TableHead>

          <TableBody>
            {[...money, ...moneyFixed].map((creditMoney, idx) => {
              if (creditMoney.type !== group) return null;
              if (!creditMoney.title.toLowerCase().includes(search.toLowerCase())) return null;
              const locked = !editable || creditMoney.isLocked;
              const id = creditMoney.id || creditMoney.key || 0;

              return (
                <TableRow key={idx} className={locked ? cls.locked : undefined}>
                  <TableCell className={cls.tvalue}>
                    {locked && <LockFilled className={cls.lockedIcon} />}
                    <Input
                      disabled={locked}
                      bordered={!creditMoney.title}
                      value={creditMoney.title}
                      onKeyPress={locked ? e => e.preventDefault() : undefined}
                      onChange={e => onChange(id, 'title', e.target.value)}
                      status={creditMoney.title ? undefined : 'error'}
                    />
                  </TableCell>
                  {group === 'credit_fee' ? (
                    <TableCell className={cls.tvalue}>
                      {locked && <LockFilled className={cls.lockedIcon} />}
                      <Select
                        defaultValue={creditMoney.subtitle}
                        bordered={false}
                        style={{ width: '100%' }}
                        disabled={locked}
                        onChange={!locked ? value => onChange(id, 'subtitle', value) : undefined}
                        options={[
                          { value: 'Cash', label: 'Cash' },
                          { value: 'External invoice', label: 'External invoice' },
                        ]}
                      />
                    </TableCell>
                  ) : null}
                  <TableCell className={cls.tvalue}>
                    {locked && <LockFilled className={cls.lockedIcon} />}
                    <InputNumber
                      disabled={locked}
                      prefix={<DollarCircleOutlined className={cls.amountIcon} />}
                      bordered={+creditMoney.amount >= 0 ? false : true}
                      value={creditMoney.amount}
                      onChange={cost => onChange(id, 'amount', +`${cost}`)}
                      controls={locked ? false : undefined}
                      onKeyDown={locked ? e => e.preventDefault() : undefined}
                      className={cls.amountInput}
                      min={0} max={999999999}
                      status={+creditMoney.amount >= 0 ? undefined : 'error'}
                    />
                  </TableCell>
                  <TableCell className={cls.tvalue}>
                    {locked && <LockFilled className={cls.lockedIcon} />}
                    <DatePicker
                      className={cls.dateInput}
                      bordered={!creditMoney.date}
                      status={creditMoney.date ? undefined : 'error'}
                      allowClear={false}
                      disabled={locked}
                      value={creditMoney.date ? moment(creditMoney.date) : null}
                      onKeyDown={event => {
                        if (locked) return event.preventDefault();
                        const input = event.target as HTMLInputElement;
                        input.value = MASKED.resolve(input.value);
                        setTimeout(() => {
                          const momentDate = moment(input.value, DATE_FORMAT, true);
                          if (momentDate.isValid()) {
                            onChange(id, 'date', momentDate.format('YYYY-MM-DD'));
                          }
                        }, 200);
                      }}
                      onChange={selectedValue => {
                        if (locked) return;
                        const momentDate = moment(selectedValue, DATE_FORMAT, true);
                        if (momentDate.isValid()) {
                          onChange(id, 'date', momentDate.format('YYYY-MM-DD'));
                        }
                      }}
                      picker="date"
                      format={DATE_FORMAT}
                      disabledDate={(current) => current && current > moment().endOf('day')}
                    />
                  </TableCell>
                  <TableCell className={cls.deleteCol}>
                    {!locked && (
                      <ConfirmDelete cb={() => onDelete(id)}>
                        <IconButton><DeleteOutlined className={cls.deleteIcon} /></IconButton>
                      </ConfirmDelete>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}

            {/* TOTAL ROW */}
            <TableRow className={cls.totalRow}>
              <TableCell className={cls.totalLabel}>Total</TableCell>
              {group === 'credit_fee' ? (
                <TableCell className={cls.tvalue}></TableCell>
              ) : null}
              <TableCell className={cls.totalValue}>
                {moneyFormatter.format(summary)}
              </TableCell>
              <TableCell className={cls.tvalue}></TableCell>
              {editable && <TableCell className={cls.tvalue}></TableCell>}
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const useStyles = makeStyles({
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  addBtn: {
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 8,
    textTransform: 'none',
    border: '1px solid #042E6B',
    '& svg': {
      marginRight: 5,
    },
  },
  thead: {
    fontWeight: 500,
  },
  deleteCol: {
    padding: '0 8px !important',
    minWidth: '30px !important',
    maxWidth: '30px !important',
    textAlign: 'center',
  },
  deleteIcon: {
    color: 'maroon',
    fontSize: 18,
  },
  locked: {
    '& input': {
      color: '#333 !important',
      background: 'white !important',
    },
  },
  lockedIcon: {
    fontSize: 12,
    color: '#CCC',
    position: 'absolute',
    top: 3,
    left: 3,
    zIndex: 1,
  },
  tvalue: {
    position: 'relative',
    fontSize: 12,
    padding: '1px',
    minHeight: 30,
  },
  text: {
    paddingLeft: '20px !important',
    color: '#000 !important',
    fontSize: '15px !important',
  },
  totalRow: {
    height: '45px !important',
  },
  totalLabel: {
    paddingLeft: '20px !important',
    color: '#042E6B !important',
    fontSize: '15px !important',
    fontWeight: 500,
  },
  totalValue: {
    paddingLeft: '40px !important',
    color: '#042E6B !important',
    fontSize: '15px !important',
  },
  amountIcon: {
    color: '#CCC',
    marginRight: 10,
  },
  amountInput: {
    width: '100%',
  },
  dateInput: {
    '& .ant-picker-input': {
      marginLeft: 15,
      borderBottom: 'none !important',
    },
  },
  searchInput: {
    width: 220,
    height: 37,
    borderRadius: 8,
    border: '1px solid #042E6B',
  },
});

const MAX_ROWS = 50;
const DATE_FORMAT = 'DD.MM.YYYY';
const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: new Date().getFullYear() },
  },
  format: (date: Date) => moment(date).format(DATE_FORMAT),
  mask: Date,
  parse: (date: string) => moment(date, DATE_FORMAT),
  pattern: DATE_FORMAT,
});
