import { PropsWithChildren, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

interface Props {
  height?: number;
  paddingRight?: number;
  paddingLeft?: number;
  scrollBottom?: boolean;
  autoHeight?: boolean;
}

export const Scroll = ({
  children, height, paddingRight, paddingLeft, scrollBottom, autoHeight,
}: PropsWithChildren<Props>) => {
  const scrollRef = useRef<Scrollbars>();

  return (
    <Scrollbars style={{ height }}
      ref={(ref: any) => {
        scrollRef.current = ref;
      }}
      autoHeight={autoHeight}
      onUpdate={({ scrollHeight, clientHeight }) => {
        if (scrollBottom && scrollHeight > clientHeight) {
          scrollRef.current?.scrollToBottom();
        }
      }}
    >
      <div style={{ paddingRight, paddingLeft }}>
        {children}
      </div>
    </Scrollbars>
  );
};
