import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';

import { reportsHttp } from '@network';
import { moneyFormatter, querySerialize } from '@utils';
import { InitialCard } from '@components';
import { useSettingsContext } from '@providers/settings-provider';
import { LinkBlockBtn } from './LinkBlockBtn';

type ReportKey = 'total' | 'signed' | 'repaid' | 'delay' | 'restructured';

export const GeneralNumbersReport = ({ range }: {range: string}) => {
  const cls = useStyles();
  const { admin } = useSettingsContext();
  const [numbers, setNumbers] = useState<Record<string, any>>({});

  const loadNumbers = async () => {
    try {
      const nextNumbers = await reportsHttp.generalNumbers({ range });
      setNumbers(nextNumbers);
    } catch (e) {}
  };

  useEffect(() => {
    if (admin) {
      loadNumbers();
    }
  }, [range]);

  const finCredits = numbers?.financeCredits || 0;
  const finCreditsAmount = moneyFormatter.format(numbers?.amounts?.financeCredits || 0);

  const blocks: {key: ReportKey, title: string, link: string, tooltip?: string}[] = [
    {
      key: 'total',
      title: 'Signed Credits',
      link: '/credit?' + querySerialize({
        filter: JSON.stringify({
          status: ['signed', 'repaid', 'delay', 'restructure'],
          createdAt: range ? `signatureAt:${range}` : 'signatureAt:',
        }),
      }),
      tooltip: `Show the credits filtered by “Last Status Change” date for “Signed, Repaid, Delay, Restructure” statuses. Includes ${finCredits} "Other credits" records of CALERA with Credit ${finCreditsAmount}`,
    },
    {
      key: 'signed',
      title: 'Active credits',
      link: '/credit?' + querySerialize({
        filter: JSON.stringify({
          status: ['signed'],
          createdAt: range ? `signatureAt:${range}` : 'signatureAt:',
        }),
      }),
      tooltip: 'Show the credits filtered by “Last Status Change” date for “Signed" status',
    },
    {
      key: 'repaid',
      title: 'Repaid credit',
      link: '/credit?' + querySerialize({
        filter: JSON.stringify({
          status: ['repaid'],
          createdAt: range ? `signatureAt:${range}` : 'signatureAt:',
        }),
      }),
      tooltip: `Show the credits filtered by “Last Status Change” date for “Repaid” status. Includes ${finCredits} "Other credits" records of CALERA with Credit ${finCreditsAmount}`,
    },
    {
      key: 'delay',
      title: 'Delayed credits',
      link: '/credit?' + querySerialize({
        filter: JSON.stringify({
          status: ['delay'],
          createdAt: range ? `signatureAt:${range}` : 'signatureAt:',
        }),
      }),
      tooltip: 'Show the credits filtered by “Last Status Change” date for “Delay” status',
    },
    {
      key: 'restructured',
      title: 'Restructured credits',
      link: '/credit?' + querySerialize({
        filter: JSON.stringify({
          status: ['restructure'],
          createdAt: range ? `signatureAt:${range}` : 'signatureAt:',
        }),
      }),
      tooltip: 'Show the credits filtered by “Last Status Change” date for “Restructure” status',
    },
  ];

  return (
    <InitialCard left="Credits" extraPadding autoHeight mt={22}>
      <br />
      <div className={cls.list}>
        {blocks.map(block => {
          const { amounts } = numbers as any;
          const amount = amounts ? amounts[block.key] : undefined;

          return (
            <div key={block.key} className={cls.item}>
              <LinkBlockBtn
                tooltip={block.tooltip}
                key={block.title}
                title={block.title}
                link={block.link}
                total={numbers[block.key] as number || 0}
                amount={amount}
              />
            </div>
          );
        })}
      </div>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  list: {
    width: '100%',
    display: 'flex',
    gap: 20,
  },
  item: {
    width: '20%',
  },
});
