import { clsx } from 'clsx';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Datagrid,
  DatagridProps,
  useResourceContext,
  usePaginationState,
  useRedirect,
} from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import get from 'lodash/get';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// import { useScrollPosition } from '@hooks';
import { LocalStorage } from '@utils';
import { useSettingsContext } from '@providers/settings-provider';
import cls from './grid.module.css';

const arrayToSelection = (values: string[]) =>
  values.reduce((selection, columnName) => {
    selection[columnName] = true;

    return selection;
  }, {} as any);

interface GripProps {
  defaultColumns?: string[];
  storage?: {
    get: (key: string) => void;
    set: (key: string, value: any) => void
  };
  children: any;
  className?: string;
}

type SelectionType = {[key: string]: boolean};

export const Grid = ({
  children, defaultColumns = [], storage = LocalStorage, className, ...rest
}: DatagridProps & GripProps) => {
  const resource = useResourceContext();
  const location = useLocation();
  const redirect = useRedirect();
  const { perPage, setPerPage } = usePaginationState();
  const { settingsOpened, setSettingsOpened } = useSettingsContext();
  // const scrollPosition = useScrollPosition();

  const storagePerPageKey = `perPage-${resource}`;
  const storagePerPageValue = LocalStorage.get(storagePerPageKey);

  useEffect(() => {
    if (location.search) {
      const urlParams = new URLSearchParams(location.search);
      const queryPerPage = urlParams.get('perPage');

      if (queryPerPage && +queryPerPage !== perPage) {
        setPerPage(+queryPerPage);
      }
    }
    if (storagePerPageValue && storagePerPageValue !== perPage) {
      setPerPage(+storagePerPageValue);
    }
  }, [location.search]);

  useEffect(() => {
    if (storagePerPageValue !== perPage) {
      LocalStorage.set(storagePerPageKey, perPage);
    }
  }, [perPage]);

  const getColumnLabels = () => {
    return filter(
      React.Children.map(
        children,
        field =>
          field && {
            source: get(field, ['props', 'source']),
            label: get(field, ['props', 'label']),
          },
      ),
      item => item && item.source,
    );
  };

  const getColumnNames = () => {
    return filter(React.Children.map(children, field => get(field, ['props', 'source'])));
  };

  const getInitialSelection = () => {
    const previousSelection = storage.get(`${resource}`);
    if (!isEmpty(previousSelection)) return previousSelection;

    const columns = !isEmpty(defaultColumns) ? defaultColumns : getColumnNames();
    const selection = arrayToSelection(columns);
    storage.set(`${resource}`, selection);

    return selection;
  };

  const [selection, setSelection] = useState<SelectionType>(getInitialSelection());

  // updates the storage with the internal state value
  const updateStorage = (nextSelection: SelectionType) => {
    storage.set(`${resource}`, nextSelection);
  };

  const toggleColumn = (columnName: string) => {
    const nextSelection = {
      ...selection,
      [columnName]: !selection[columnName],
    };
    setSelection(nextSelection);
    updateStorage(nextSelection);
  };

  const renderChild = (child: JSX.Element | null) => {
    const source = get(child, ['props', 'source']);
    // show children without source, or children explicitly visible
    if (child && (!source || selection[source])) {
      return React.cloneElement(child, {});
    }

    return null;
  };

  const changePageSize = (e: any) => {
    const nextPageSize = +e.target.value || 10;
    setPerPage(nextPageSize);
  };

  const onModalClose = () => {
    setSettingsOpened(false);
    if (perPage) {
      const queryParams = updateQueryParameter(location.search, 'perPage', perPage);
      const url = `/${resource}${queryParams}`;
      redirect(url);
    }
  };

  const columns = getColumnLabels();

  return (
    <div className={clsx(cls.box, className)}>
      <Modal open={settingsOpened} onClose={onModalClose} className={cls.modal}>
        <Box className={cls.modalBox}>
          <div className={cls.modalTitle}>Columns</div>
          <FormGroup className={cls.columns} row>
            {columns.map(column => {
              const label = column.label
                || column.source.charAt(0).toUpperCase() + column.source.slice(1);
              const handleChange = () => toggleColumn(column.source);
              return (
                <FormControlLabel
                  key={column.source}
                  className="checkbox"
                  control={
                    <Checkbox
                      defaultChecked={selection[column.source]}
                      color="secondary"
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={label}
                />
              );
            })}
          </FormGroup>

          <div className={cls.paging}>
            <div className={cls.modalTitle}>Paging</div>
            <label className={cls.pagingLabel}>
              <span className={cls.pagingText}>Rows per page:</span>
              <Select
                value={perPage}
                label={false}
                onChange={changePageSize}
                size="small"
                variant="standard"
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </label>
          </div>

          <Button
            onClick={onModalClose}
            className={cls.saveBtn}
            type="submit"
            color="warning"
            variant="contained"
            size="large"
          >Apply</Button>
        </Box>
      </Modal>

      <Datagrid
        {...rest}
        className={'tableScrolled'}
        // className={scrollPosition > 190 ? 'tableScrolled' : ''}
      >
        {React.Children.map(children, renderChild)}
      </Datagrid>
    </div>
  );
};

export default Grid;

function updateQueryParameter(url: string, param: string, value: string | number) {
  if (!url.includes('?')) {
    return `?${param}=${value}`;
  }
  if (!url.includes(`${param}=`)) {
    return `${url}&${param}=${value}`;
  }

  const regex = new RegExp('(?<=[?|&])(' + param + '=)[^\&]+', 'i');

  return url.replace(regex, param + '=' + value);
}
