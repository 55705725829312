import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';

import { ParCredit, ParCreditKey, reportsHttp } from '@network';
import { querySerialize } from '@utils';
import { InitialCard } from '@components';
import { useSettingsContext } from '@providers/settings-provider';
import { LinkBlockBtn } from './LinkBlockBtn';

export const ParCreditsReport = ({ range }: {range: string}) => {
  const cls = useStyles();
  const { admin } = useSettingsContext();
  const [records, setRecords] = useState<ParCredit[]>(defaultRecords);

  const loadNumbers = async () => {
    try {
      const nextRecords = await reportsHttp.parCredits({ range });
      setRecords(nextRecords);
    } catch (e) {}
  };

  useEffect(() => {
    admin && loadNumbers();
  }, [range]);

  return (
    <InitialCard left="Delayed Records" extraPadding autoHeight mt={22}>
      <br />
      <div className={cls.list}>
        {records.map(record => {
          return (
            <div key={record.key} className={cls.item}>
              <LinkBlockBtn
                key={record.key}
                title={record.key === 'DELAY' ? 'Default' : record.key}
                link={links[record.key]}
                total={record.total}
                amount={record.amount}
              />
            </div>
          );
        })}
      </div>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  list: {
    width: '100%',
    display: 'flex',
    gap: 20,
  },
  item: {
    width: '20%',
  },
});

const defaultRecords: ParCredit[] = [
  { key: 'PAR 30', amount: 0, total: 0 },
  { key: 'PAR 60', amount: 0, total: 0 },
  { key: 'PAR 90', amount: 0, total: 0 },
  { key: 'DELAY', amount: 0, total: 0 },
  { key: 'Recovered', amount: 0, total: 0 },
];

const links: Record<ParCreditKey, string> = {
  'PAR 30': '/credit?' + querySerialize({
    displayedFilters: ['delayPar'],
    filter: JSON.stringify({ delayPar: '30' }),
  }),
  'PAR 60': '/credit?' + querySerialize({
    displayedFilters: ['delayPar'],
    filter: JSON.stringify({ delayPar: '60' }),
  }),
  'PAR 90': '/credit?' + querySerialize({
    displayedFilters: ['delayPar'],
    filter: JSON.stringify({ delayPar: '90' }),
  }),
  'DELAY': '/credit?' + querySerialize({
    displayedFilters: ['delayPar'],
    filter: JSON.stringify({ delayPar: 'Delay' }),
  }),
  'Recovered': '/credit?' + querySerialize({
    displayedFilters: ['delayPar'],
    filter: JSON.stringify({ delayPar: 'Recovered' }),
  }),
};
